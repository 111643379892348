
import { Component, Prop, Watch } from 'vue-property-decorator';
import tasqsListModule from '@/store/modules/tasqsListModule';
import { mixins } from 'vue-class-component';
import uniqid from 'uniqid';
import draggable from 'vuedraggable';
import { Debounce } from 'vue-debounce-decorator';
import workflowModule from '../../store/modules/workflowModule';
import accountModule from '@/store/modules/accountModule';
import { getComponent } from '@/utils/helpers';
import gptModule from '@/store/modules/gptModule';
import { getNameByEmail } from '@/utils/users';

@Component({
  components: {
    draggable,
    GptAutoSuggestion: () => getComponent('gpt/GptAutoSuggestionV2'),
  },
})
export default class ChecklistComponent extends mixins() {
  @Prop({ type: Array, default: () => [] }) checklistData!: any;

  @Prop({ type: Object, default: () => {} }) tasq!: any;

  @Prop({ type: Boolean, default: false }) hideAddNewItem!: boolean;

  @Prop({ type: Boolean, default: false }) showCheckbox!: boolean;

  @Prop({ type: Boolean, default: false }) hideAddRemoveItem!: boolean;

  @Prop({ type: Boolean, default: false }) hideItemBackground!: boolean;

  @Prop({ type: Boolean, default: false }) hideExpandedView!: boolean;

  @Prop({ type: Boolean, default: false }) hideAddTodo!: boolean;
  @Prop({ type: Boolean, default: false }) isTasqAiPage!: boolean;

  checklist: any = [];

  newItemText = '';

  addItemFocus = false;

  hideMainAddSection = false;

  isLoadingData = false;

  get isAddItemSectionSelected() {
    return this.addItemFocus || this.listItem.text;
  }


  localGetNameByEmail(email) {
    return getNameByEmail(email);
  }

  currentItem: any = null;

  hoverItemId = '';

  defaultItem = {
    checked: false,
    text: '',
    dueDate: null,
    dateType: 'None',
  };

  listItem = {
    checked: false,
    text: '',
    dueDate: null,
    dateType: 'None',
  };

  get reassignUsers() {
    return accountModule.reassignmentList
      .map((i) => ({
        // @ts-ignore
        text: i.name,
        value: i.email,
      }))
      .sort((a, b) => a.text.localeCompare(b.text));
  }

  get userNames() {
    return this.reassignUsers.map((i) => i.text);
  }

  async updateItemState(item) {
    console.log(item);
    this.isLoadingData = true;

    if (item.checked) {
      this.completeItems = this.completeItems.filter(
        (step) => item.id !== step.id && item.text !== step.text
      );
      let localItem = item;
      localItem.checked = !item.checked;
      this.pendingItems.push(localItem);
    } else {
      this.pendingItems = this.pendingItems.filter(
        (step) => item.id !== step.id && item.text !== step.text
      );
      let localItem = item;
      localItem.checked = !item.checked;
      localItem.date = new Date().toISOString();
      this.completeItems.push(localItem);
    }

    this.$nextTick(() => {
      this.isLoadingData = false;
      gptModule.setCompletedTodoItems(this.completeItems);
      gptModule.setPendingTodoItems(this.pendingItems);
    });
    await this.updateList();
  }

  async created() {
    gptModule.setCompletedTodoItems([]);
    const list = await workflowModule.getWellTodolist(this.tasq.wellName);
    const { CompletedTodolist, ActiveTodolist } = list;
    if (CompletedTodolist && CompletedTodolist.length) {
      this.completeItems = CompletedTodolist;
    }
    if (ActiveTodolist && ActiveTodolist.length) {
      this.pendingItems = ActiveTodolist;
    }

    gptModule.setPendingTodoItems(this.pendingItems);
    gptModule.setCompletedTodoItems(this.completeItems);

    // this.checklist = [...this.pendingItems, ...this.completeItems];

    this.isLoadingData = true;
    this.separateListItems();
    this.$nextTick(() => {
      this.isLoadingData = false;
    });
  }

  get isWorkTicketView() {
    return this.tasqListLevel === 'WorkTicket';
  }

  get isPadView() {
    return this.tasqListLevel === 'Pad';
  }

  get tasqListLevel() {
    return tasqsListModule.tasqListLevel;
  }

  pendingItems: any = [];
  completeItems: any = [];

  subTasks: any = [];

  async addSubTask(item) {
    // Find an existing item in pendingItems with the same text but a different id
    const existingItem = this.pendingItems.find(
      (step) => item.id === step.id && item.text === step.text
    );

    // If an existing item is found, push the new subtask
    if (existingItem) {
      existingItem.subTasks = existingItem.subTasks || []; // Initialize subTasks if not already an array
      existingItem.subTasks.push({
        checked: false,
        text: '',
        parentId: item.id,
        dueDate: null,
        dateType: 'None',
        createdBy: accountModule.user.email,
        id: await uniqid(),
      });
    }

    // Update the item in pendingItems
    this.pendingItems = this.pendingItems.map((step) =>
      step.id === item.id
        ? { ...item, subTasks: existingItem?.subTasks || item.subTasks }
        : step
    );
  }

  async addItemToList() {
    if (!this.listItem.text) {
      return;
    }

    this.isLoadingData = true;

    const newItem = {
      ...this.listItem,
      id: await uniqid(),
      isHovering: false,
      updatedAt: new Date().getTime(),
      subTasks: [],
    };

    if (newItem.checked) {
      this.completeItems.push(newItem);
    } else {
      this.pendingItems.push(newItem);
    }

    gptModule.setCompletedTodoItems(this.completeItems);
    gptModule.setPendingTodoItems(this.pendingItems);

    this.$nextTick(() => {
      this.isLoadingData = false;
    });
    this.resetForm();
    this.$emit('update-checklist', this.checklist);
    await this.updateList();
    this.$tasqAlert({
      title: 'Success',
      message: 'It has been successfully added to the To Do List',
      type: 'success',
    });
  }

  resetForm() {
    this.hideMainAddSection = true;
    this.$nextTick(() => {
      this.listItem = {
        checked: false,
        text: '',
        dueDate: null,
        dateType: 'None',
      };
      this.hideMainAddSection = false;
      this.addItemFocus = false;
    });
  }

  updateHoverItem(item) {
    this.hoverItemId = item.id;
  }

  async removeItemFromList(item) {
  this.isLoadingData = true;

  let itemFound = false;

  // Check if item is a main task in pendingItems
  if (!item.checked) {
    const initialPendingCount = this.pendingItems.length;
    this.pendingItems = this.pendingItems.filter(
      (step) => step.id !== item.id && step.text !== item.text
    );
    itemFound = this.pendingItems.length < initialPendingCount;
    gptModule.setPendingTodoItems(this.pendingItems);
  }

  // Check if item is a main task in completeItems
  if (item.checked && !itemFound) {
    const initialCompleteCount = this.completeItems.length;
    this.completeItems = this.completeItems.filter(
      (step) => step.id !== item.id && step.text !== item.text
    );
    itemFound = this.completeItems.length < initialCompleteCount;
    gptModule.setCompletedTodoItems(this.completeItems);
  }

  // If item wasn't found as a main task, search and remove from subTasks
  if (!itemFound) {
    this.pendingItems.forEach((task) => {
      if (task.subTasks && Array.isArray(task.subTasks)) {
        task.subTasks = task.subTasks.filter(
          (subTask) => subTask.id !== item.id && subTask.text !== item.text
        );
      }
    });

    this.completeItems.forEach((task) => {
      if (task.subTasks && Array.isArray(task.subTasks)) {
        task.subTasks = task.subTasks.filter(
          (subTask) => subTask.id !== item.id && subTask.text !== item.text
        );
      }
    });
  }

  this.$nextTick(() => {
    this.isLoadingData = false;
  });
  this.separateListItems();
  await this.updateList();
}


  separateListItems() {
    if (this.pendingItems.length) {
      this.currentItem = this.pendingItems.sort(
        (a, b) => b.updatedAt - a.updatedAt
      )[this.pendingItems.length - 1];
    } else {
      this.currentItem = null;
    }
  }

  @Debounce(500)

async updateList() {
  this.separateListItems();
  let hasCurrentUserAsAssignee = false;

  const newPayload = this.pendingItems.map((task) => {
    const assignees = [];

    // Check for mentions in main task text
    this.reassignUsers.forEach((user) => {
      const atMention = '@' + user.text;
      if (task.text.includes(atMention)) {
        assignees.push(user.value);
      }
    });

    // Check for mentions in each subtask's text
    if (task.subTasks && Array.isArray(task.subTasks)) {
      task.subTasks.forEach((subTask) => {
        this.reassignUsers.forEach((user) => {
          const atMention = '@' + user.text;
          if (subTask.text.includes(atMention) && !assignees.includes(user.value)) {
            assignees.push(user.value);
          }
        });
      });
    }

    // Check if the current user is one of the assignees
    if (assignees.includes(accountModule.user.email)) {
      hasCurrentUserAsAssignee = true;
    }

    // Update task assignees
    task.assignees = assignees;
    return task;
  });

  // Post updated task list
  await workflowModule.postTodolist({
    activeList: [...newPayload],
    completedList: [...this.completeItems],
    nodeID: this.tasq.wellName,
    level: this.tasq.level,
  });

  await gptModule.getWellStatuses();
}


  formatDateString(dateString) {
    const date = new Date(dateString);
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];

    if (isNaN(date.getTime())) {
      return 'Invalid date';
    }

    const formattedDate = `${
      months[date.getMonth()]
    } ${date.getDate()}, ${date.getFullYear()}`;
    return formattedDate;
  }

  async updateCurrentItem() {
    const checklist = this.checklist.map((item) => {
      if (item.id === this.currentItem.id) {
        item = this.currentItem;
      }
      return item;
    });
    this.checklist = checklist;
    await this.updateList();
  }

  @Debounce(2000)
  updateListText(e) {
    e.target.style.height = 'auto';
    e.target.style.height = `${e.target.scrollHeight}px`;
    this.$emit('update-checklist', this.checklist);
  }
}
